/* eslint no-underscore-dangle: 0 */
import React, { useEffect } from 'react';
import {
  Badge,
  Card,
  Typography,
  withStyles,
} from '@material-ui/core';
import api from '~/services/api';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ZendeskAPI } from 'react-zendesk';
import styles from './styles';
import Main from '../Main';
import { logoutUser } from '~/store/auth/actions';
import { isSessionValid } from '~/services/authentication';
import { checkModulePermission, modules as modulesUrls } from '~/utils';
import icons from '~/utils/modulesIcons';
import PF2MSwitch from '~/components/PF2MSwitch';
import DynamicModal from './dynamicModal';

const Home = ({
  classes, modules, roles, config, version,
}) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const [dynamicModal, setDynamicModal] = React.useState(0);

  useEffect(() => {
    (async () => {
      if (version) {
        const sessionValid = await isSessionValid(api, version);
        if (!sessionValid) dispatch(logoutUser(true));
      }
    })();
  }, [dispatch, version]);

  useEffect(() => {
    ZendeskAPI('webWidget', 'show');
    return () => {
      ZendeskAPI('webWidget', 'hide');
    };
  }, []);

  const handleModuleUrl = (name) => {
    if (name === 'REPORTS') return config.find(c => c.key === 'url_reports')?.value;
    if (!checkModulePermission(roles, name) || !modulesUrls[name]) return '';
    return modulesUrls[name].url;
  };

  const handleDynamicIcon = (name, urls) => {
    if (checkModulePermission(roles, 'DYNAMIC') && modulesUrls[name]) {
      return urls[name].dynamic ? (<icons.Dynamic />) : 0;
    }
    return urls[name].dynamic ? (<icons.DynamicOff />) : 0;
  };

  const renderCard = (moduleValue, moduleName, Icon) => {
    const enableOpenPit = config.find(c => c.key === 'enable_openpit' && c.value === '1');
    return (
      <Card
        className={classnames(
          classes.cardContent,
          modulesUrls[moduleValue] ? classes.cardLink : '',
          checkModulePermission(roles, moduleValue) ? '' : classes.cardDisabled,
        )}
        key={moduleValue}
      >
        {Icon ? (
          <Icon
            className={classes.cardIcon}
          />
        ) : (
          <icons.Warning className={classes.cardIcon} />
        )}
        <Typography
          variant="h4"
          align="center"
          className={classes.cardTitle}
        >
          {translate(`common:${enableOpenPit && moduleName === 'UndergroundModule' ? 'OpenPitModule' : moduleName}`)}
        </Typography>
      </Card>
    );
  };

  const renderModules = () => modules
    .filter(m => (modulesUrls[m.name] && modulesUrls[m.name].url))
    .map(m => (
      <Badge
        className={[
          classes.badge,
          checkModulePermission(roles, 'DYNAMIC') ? '' : classes.badgeDisabled,
        ].join(' ')}
        overlap="rectangular"
        badgeContent={handleDynamicIcon(m.name, modulesUrls)}
        color="primary"
        key={m.name}
      >
        <Link
          onClick={() => (checkModulePermission(roles, m.name) ? null : setDynamicModal(m.name))}
          exact="true"
          to={handleModuleUrl(m.name)}
          className={classnames(classes.link, checkModulePermission(roles, m.name) ? '' : classes.linkDisabled)}
          target={m.name === 'REPORTS' ? '_blank' : undefined}
        >
          {renderCard(m.name, m.caption, modulesUrls[m.name].icon)}
        </Link>
      </Badge>
    ));

  return (
    <>
      <Main type={'home'}>
        <div className={classes.container}>
          {modules ? (
            <>
              <div className={classes.cardsContainer}>
                {renderModules()}
              </div>
            </>
          ) : null}
          <button
            onClick={() => (checkModulePermission(roles, 'DYNAMIC') ? null : setDynamicModal('DYNAMIC'))}
            type="button"
            tabIndex={0}
            className={classes.dynamicButton}
          >
            <div>
              <icons.Dynamic />
              Dynamic
              <PF2MSwitch
                checked={!!checkModulePermission(roles, 'DYNAMIC')}
              />
            </div>
          </button>
        </div>
      </Main>
      <DynamicModal
        modalData={dynamicModal}
        closeModal={() => setDynamicModal(null)}
      />
    </>
  );
};

Home.propTypes = {
  classes: PropTypes.object.isRequired,
  modules: PropTypes.array,
  roles: PropTypes.array,
  config: PropTypes.array,
  version: PropTypes.string,
};

Home.defaultProps = {
  modules: [],
  roles: [],
  config: [],
  version: '',
};


const mapStateToProps = state => ({
  modules: state.auth.user._modules,
  roles: state.auth.user._roles,
  config: state.auth.user.config,
  version: state.auth.version,
});

export default connect(mapStateToProps, {})(withStyles(styles)(Home));
