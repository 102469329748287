/* eslint max-len:0 */
import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import {
  withStyles,
  Checkbox,
  ListItemText,
  FormControl,
  FormGroup,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import Footer from '~/pages/Manager/Footer';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import { getElements, getSubElements } from '~/store/manager/actions';
import {
  getProjectGroups,
  getProjectSubElements,
  updateProjectSubElements,
  resetProjectSubElements,
  setProjectSubElementsValues,
} from '~/store/underground/actions';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import PF2MCheckboxSelect from '~/components/PF2MCheckboxSelect';


const ElementTypeCheckbox = ({
  checked, field, label, onChange,
}) => (
  <FormControl>
    <FormGroup>
      <FormControlLabel
        style={{ marginLeft: 0 }}
        control={(
          <Checkbox
            checked={checked}
            onChange={e => onChange(e.target.checked, field)}
          />
        )}
        label={(
          <Typography style={styles.checkboxLabel}>
            {label}
          </Typography>
        )}
      />

    </FormGroup>
  </FormControl>
);

ElementTypeCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  field: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const ProjectSubElementsTab = ({ classes }) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [projectGroup, setProjectGroup] = useState(0);
  const [element, setElement] = useState(0);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [initialProjectSubelements, setInitialProjectSubelements] = useState([]);
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const projectSubElements = useSelector(state => state.underground.projectSubElements);
  const isProjectSubElementsDirty = useSelector(state => state.underground.isProjectSubElementsDirty);
  const projectGroups = useSelector(state => state.underground.projectGroups);
  const elements = useSelector(state => state.manager.elements);
  const subElements = useSelector(state => state.manager.subElements);

  useEffect(() => {
    async function fetchData() {
      dispatch(getProjectSubElements());
      dispatch(getProjectGroups());
      dispatch(getElements());
      dispatch(getSubElements());
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  useEffect(() => {
    if (!isProjectSubElementsDirty) {
      setInitialProjectSubelements(projectSubElements);
    }
  }, [initialProjectSubelements, projectSubElements, isProjectSubElementsDirty]);

  const handleUpdate = () => {
    const filtered = projectSubElements.map(p => ({
      ...p,
      update_status: initialProjectSubelements.some(
        k => p.id === k.id && p.active === k.active && p.update_status === 'I',
      ) ? '' : p.update_status,
    }));
    // p.id === k.id && p.active === k.active && p.update_status === 'I'
    setInitialProjectSubelements([]);
    return updateProjectSubElements(filtered);
  };

  const renderSubElements = useMemo(() => subElements
    .filter(subElem => subElem.active && subElem.sub_element_type_id !== null)
    .filter(e => element === 0 || e.id_element === element)
    .map((s) => {
      const existingItem = projectSubElements.find(a => a.id_element_point === s.id);
      return (
        <div key={s.id} style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            checked={projectSubElements.some(a => a.id_element_point === s.id
              && a.update_status !== 'D'
              && a.id_project_group === projectGroup)}
            color="primary"
            name={s.id.toString()}
            onChange={e => dispatch(setProjectSubElementsValues(projectGroup, s.id, e.target.checked))}
            disabled={(projectGroup === 0 || (existingItem && existingItem.id_project_group !== projectGroup))}
          />
          <ListItemText secondary={s.name} style={{ padding: 0 }} />
        </div>
      );
    }), [subElements, projectSubElements, projectGroup, dispatch, element]);

  const renderProjectGroups = useCallback(() => [
    { value: 0, label: translate('common:Select') },
    ...projectGroups.map(e => ({ value: e.id, label: e.name })),
  ], [projectGroups, translate]);

  const handleChangeProjectGroup = (e) => {
    const selected = projectSubElements.find(m => m.id_project_group === projectGroup);
    if (e.target.value !== projectGroup && selected && selected.isDirty) {
      setIsAlertOpen(true);
    } else {
      setProjectGroup(e.target.value);
    }
  };

  const handleChangeElement = (e) => {
    const selected = projectSubElements.find(m => m.id_element === element);
    if (e.target.value !== element && selected && selected.isDirty) {
      setIsAlertOpen(true);
    } else {
      setElement(e.target.value);
    }
  };

  const filterElementCheckBox = (checkeds, item) => {
    if (!item.active) {
      return false;
    }
    if (!checkeds.is_origin && !checkeds.is_destination) {
      return true;
    }
    if (item.is_origin === checkeds.is_origin && checkeds.is_origin && !item.is_destination) {
      return true;
    }
    if (item.is_destination === checkeds.is_destination
        && checkeds.is_destination && !item.is_origin) {
      return true;
    }
    return false;
  };

  return (
    <div className={classes.tabContainer}>
      <div style={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
        <div className={classes.containerTypeSelector}>
          <p className={classes.labelTypeSelector}>

            {translate('common:FilterByProjectGroup')}
          </p>
          <PF2MSearchSelectOutlined
            value={projectGroup}
            onChange={handleChangeProjectGroup}
            fullWidth={false}
            className={classes.typeSelector}
          >
            {renderProjectGroups()}
          </PF2MSearchSelectOutlined>
        </div>
        <div className={classes.containerTypeSelector}>
          <PF2MCheckboxSelect
            inputLabel={translate('common:Element')}
            options={elements}
            onChange={handleChangeElement}
            filter={filterElementCheckBox}
            checkboxs={
              [
                { field: 'is_origin', label: translate('common:Origins') },
                { field: 'is_destination', label: translate('common:Destinations') },
              ]
            }
            map={e => ({ value: e.id, label: e.name })}
            selectedValue={element}
            initialState={{ is_origin: false, is_destination: false }}
            headOptions={[{ value: 0, label: translate('common:All') }]}
          />
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '100%' }}>
          <span className={classes.label}>
            {translate('common:OriginSubElements')}
            :
          </span>
          <PF2MScrollbar className={classes.scrollbars} style={{ height: 'auto' }}>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
              {renderSubElements}
            </div>
          </PF2MScrollbar>
        </div>
      </div>
      <div style={{ marginTop: 20 }}>
        <Footer
          isDirty={isProjectSubElementsDirty}
          discard={() => dispatch(resetProjectSubElements())}
          sendData={() => dispatch(handleUpdate())}
          style={{ marginTop: 10 }}
        />
      </div>
      <PF2MAlertDialog
        hasCancel={false}
        confirmText={translate('common:Understood')}
        description={translate('validation:YouHaveUnsavedData')}
        open={isAlertOpen}
        onConfirm={() => setIsAlertOpen(false)}
      />
    </div>
  );
};

ProjectSubElementsTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

ProjectSubElementsTab.defaultProps = {};

export default withStyles(styles)(ProjectSubElementsTab);
