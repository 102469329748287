import backgroundImage from '~/assets/background.jpg';

const styles = {
  grow: {
    flexGrow: 1,
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
  },
  title: {
    color: '#FFF',
    '&:hover': {
      underline: 'none',
      color: '#FFF',
    },
  },
  secondaryTitle: {
    color: '#F2AA00',
  },
  separator: {
    marginLeft: 10,
    marginRight: 10,
  },
  active: {
    color: '#F2AA00',
  },
  appBar: {
    background: `url(${backgroundImage}) center no-repeat`,
    backgroundSize: 'cover',
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
};

export default styles;
