export const paperProps = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
};

export const styles = {
  menu: {
    '& .MuiPaper-root': {
      color: '#FFC300',
      backgroundColor: '#111110',
      width: 280,
    },
    '& .MuiButton-outlined': {
      color: '#FFC300',
      borderColor: '#FFC300',
      width: '100%',
      justifyContent: 'space-between',
    },
    '& .MuiDivider-root': {
      backgroundColor: '#FFC300',
      margin: '8px 0 0px 0',
    },
  },
  version: {
    display: 'flex',
    justifyContent: 'right',
    fontSize: 12,
  },
  avatarContainer: {
    marginLeft: '240px',
  },
};

export default { paperProps, styles };
