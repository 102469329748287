import React, { useState, useEffect, useMemo } from 'react';
import {
  FormControl, FormGroup, FormLabel, withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import PF2MTable from '~/components/PF2MTable';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import Footer from '~/pages/Manager/Footer';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import {
  getProjectGroups,
  getPriorities,
  setPriorityValue,
  resetPriorities,
  updatePriorities,
} from '~/store/underground/actions';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import PF2MCheckboxSelect from '~/components/PF2MCheckboxSelect';
import { getElements, getSubElements } from '~/store/manager/actions';

const usePriorities = () => {
  const dispatch = useDispatch();
  const allPriorities = useSelector(state => state.underground.priorities);
  const [filters, sF] = useState({
    projectGroupId: -1,
    elementId: 0,
    subElementId: 0,
  });

  useEffect(() => {
    dispatch(getProjectGroups());
    dispatch(getElements());
    dispatch(getSubElements());
    dispatch(getPriorities());
  }, [dispatch]);

  const priorities = useMemo(() => {
    const { projectGroupId, elementId, subElementId } = filters;
    return allPriorities
      .filter(p => p.id_project_group === projectGroupId || projectGroupId === -1)
      .filter(p => elementId === 0 || p.id_element === elementId)
      .filter(p => subElementId === 0 || p.id_element_point === subElementId);
  }, [filters, allPriorities]);

  const setFilters = (field, value) => {
    if (field === 'elementId') {
      return sF(f => ({ ...f, [field]: value, subElementId: 0 }));
    }
    return sF(f => ({ ...f, [field]: value }));
  };

  return [priorities, filters, setFilters];
};

const SubElementsPriorityTab = ({ classes }) => {
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isAnswerOpen, setIsAnswerOpen] = useState(false);
  const isPrioritiesDirty = useSelector(state => state.underground.isPrioritiesDirty);
  const [priorities, filters, setFilters] = usePriorities();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const projectGroups = useSelector(state => state.underground.projectGroups);
  const elements = useSelector(state => state.manager.elements);
  const subElements = useSelector(state => state.manager.subElements);

  const columns = [
    {
      field: 'project_group_name',
      title: translate('common:ProjectGroup'),
      defaultValue: translate('common:NotConnected'),
    },
    { field: 'element_name', title: translate('common:Elements') },
    { field: 'element_point_name', title: translate('common:SubElements') },
    {
      field: 'priority',
      title: translate('common:Priority'),
      editable: true,
      width: '200px',
      type: 'number',
    },
  ];

  const filterElementCheckBox = (checkeds, item) => {
    if (!item.active) {
      return false;
    }
    if (!checkeds.is_origin && !checkeds.is_destination) {
      return true;
    }
    if (item.is_origin === checkeds.is_origin && checkeds.is_origin && !item.is_destination) {
      return true;
    }
    if (item.is_destination === checkeds.is_destination
        && checkeds.is_destination && !item.is_origin) {
      return true;
    }
    return false;
  };

  return (
    <div className={classes.tabContainer}>
      <div className={classes.containerTypeSelector}>
        <div style={{ display: 'flex', alignItems: 'baseline', margin: 10 }}>
          <FormControl>
            <FormLabel className={classes.labelTypeSelector}>
              {translate('common:ProjectGroup')}
            </FormLabel>
            <PF2MSearchSelectOutlined
              value={filters.projectGroupId}
              onChange={e => setFilters('projectGroupId', e.target.value)}
            >
              {[
                { value: -1, label: translate('common:All') },
                { value: 0, label: translate('common:NotConnected') }]
                .concat(...projectGroups
                  .map(e => ({ value: e.id, label: e.name })))}
            </PF2MSearchSelectOutlined>
          </FormControl>
          <FormControl>
            <PF2MCheckboxSelect
              classes={{ formLabel: classes.labelTypeSelector }}
              onChange={e => setFilters('elementId', e.target.value)}
              name="elementId"
              inputLabel={translate('common:Element')}
              options={elements}
              checkboxs={
                [
                  { field: 'is_origin', label: translate('common:Origins') },
                  { field: 'is_destination', label: translate('common:Destinations') },
                ]
              }
              filter={filterElementCheckBox}
              map={e => ({ value: e.id, label: e.name })}
              selectedValue={filters.elementId}
              initialState={{ is_origin: false, is_destination: false }}
              headOptions={[{ value: 0, label: translate('common:All') }]}
            />
          </FormControl>
          <FormControl>
            <FormLabel className={classes.labelTypeSelector}>
              {translate('common:SubElement')}
            </FormLabel>
            <FormGroup>
              <PF2MSearchSelectOutlined
                value={filters.subElementId}
                onChange={e => setFilters('subElementId', e.target.value)}
              >
                {[{ value: 0, label: translate('common:All') }]
                  .concat(...subElements
                    .filter(item => !filters.elementId || item.id_element === filters.elementId)
                    .map(e => ({ value: e.id, label: e.name })))}
              </PF2MSearchSelectOutlined>
            </FormGroup>
          </FormControl>
        </div>
      </div>
      <PF2MTable
        data={priorities}
        columns={columns}
        updateItem={(row, field, value) => {
          dispatch(setPriorityValue(row, field, value));
        }}
      />
      <Footer
        isDirty={isPrioritiesDirty}
        discard={() => dispatch(resetPriorities())}
        sendData={() => {
          if (priorities.some(p => p.priority === 0)) {
            setIsAnswerOpen(true);
            return;
          }

          dispatch(updatePriorities(priorities));
        }}
      />
      <PF2MAlertDialog
        hasCancel={false}
        confirmText={translate('common:Understood')}
        description={translate('validation:CantHaveSubElementsWithSamePriority')}
        open={isAlertOpen}
        onConfirm={() => setIsAlertOpen(false)}
      />
      <PF2MAlertDialog
        hasCancel
        confirmText={translate('common:Yes')}
        description={translate('validation:ThereAreUnsavedPriorities')}
        open={isAnswerOpen}
        onClose={() => setIsAnswerOpen(false)}
        onConfirm={() => {
          setIsAnswerOpen(false);
          dispatch(updatePriorities(priorities));
        }}
      />
    </div>
  );
};

SubElementsPriorityTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

SubElementsPriorityTab.defaultProps = {};

export default withStyles(styles)(SubElementsPriorityTab);
