import React from 'react';
import {
  AppBar, withStyles, Toolbar, Button,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';
import UserMenu from '../UserMenu';

const Fast2MineLogo = () => (
  <svg width="330" height="51" viewBox="0 0 330 51" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ padding: 5 }}>
    <path d="M92.4237 14.8327L90.6288 23.8045H106.391L105.081 30.1094H89.4166L86.943 42.4764H79.0379L85.8282 8.52783H111.484L110.174 14.8327H92.4237Z" fill="white" />
    <path d="M139.336 16.3849L134.148 42.4764H126.969L127.455 39.9054C125.289 41.8781 122.62 42.8645 119.452 42.8645C117.416 42.8645 115.539 42.396 113.826 41.4576C112.112 40.5206 110.747 39.1702 109.728 37.4077C108.709 35.6452 108.2 33.568 108.2 31.1762C108.2 28.2989 108.838 25.7039 110.116 23.3925C111.393 21.0811 113.13 19.2706 115.33 17.9611C117.529 16.6516 119.953 15.9969 122.605 15.9969C126.614 15.9969 129.443 17.3064 131.092 19.9254L131.771 16.3849H139.338H139.336ZM125.757 35.5168C126.921 34.7887 127.826 33.7797 128.473 32.4857C129.12 31.1931 129.443 29.7058 129.443 28.0238C129.443 26.2458 128.917 24.8389 127.867 23.8045C126.815 22.7702 125.352 22.2523 123.478 22.2523C121.991 22.2523 120.666 22.6164 119.502 23.3431C118.337 24.0698 117.431 25.0816 116.785 26.3742C116.137 27.6682 115.816 29.1555 115.816 30.8361C115.816 32.6141 116.341 34.021 117.392 35.0554C118.442 36.0911 119.905 36.6076 121.781 36.6076C123.268 36.6076 124.593 36.2435 125.757 35.5168Z" fill="white" />
    <path d="M143.011 42.1123C140.99 41.6114 139.414 40.9566 138.282 40.1481L141.192 34.6673C142.324 35.411 143.705 36.0093 145.339 36.4622C146.972 36.9152 148.613 37.141 150.261 37.141C151.909 37.141 153.251 36.9223 154.092 36.4862C154.933 36.0502 155.354 35.4279 155.354 34.6193C155.354 33.973 154.981 33.5045 154.239 33.2124C153.495 32.9218 152.299 32.6141 150.651 32.291C148.775 31.9354 147.231 31.5473 146.019 31.1268C144.807 30.7063 143.756 30.0191 142.867 29.0652C141.978 28.1113 141.533 26.8102 141.533 25.1606C141.533 22.2834 142.737 20.0369 145.146 18.4197C147.555 16.804 150.715 15.9954 154.627 15.9954C156.438 15.9954 158.199 16.1888 159.913 16.5768C161.626 16.9649 163.081 17.4983 164.278 18.177L161.561 23.7058C159.428 22.3807 156.857 21.7175 153.85 21.7175C152.201 21.7175 150.947 21.9602 150.09 22.4456C149.234 22.9311 148.805 23.5449 148.805 24.2886C148.805 24.9673 149.176 25.4612 149.92 25.7674C150.663 26.075 151.908 26.4066 153.653 26.7608C155.496 27.1164 157.008 27.496 158.189 27.901C159.368 28.306 160.396 28.9763 161.268 29.9133C162.141 30.8517 162.577 32.1287 162.577 33.7444C162.577 36.6542 161.357 38.9021 158.915 40.4853C156.474 42.07 153.315 42.8616 149.434 42.8616C147.171 42.8616 145.029 42.6105 143.008 42.1095L143.011 42.1123Z" fill="white" />
    <path d="M173.472 33.2618C173.408 33.5215 173.375 33.8757 173.375 34.3286C173.375 36.0107 174.28 36.8503 176.092 36.8503C177.126 36.8503 178.16 36.5427 179.196 35.9288L180.312 41.3122C178.63 42.348 176.449 42.8645 173.765 42.8645C171.308 42.8645 169.344 42.2182 167.872 40.9242C166.4 39.6316 165.665 37.8522 165.665 35.5888C165.665 34.9425 165.745 34.1819 165.907 33.3098L170.708 8.72256H178.273L176.798 16.2833H183.249L182.085 22.1027H175.634L173.472 33.2618Z" fill="white" />
    <path d="M208.705 36.0742L207.444 42.4764H181.74L182.759 37.3837L198.23 25.2594C200.106 23.7396 201.407 22.4626 202.135 21.4282C202.862 20.3939 203.226 19.3116 203.226 18.1784C203.226 17.0453 202.797 16.1422 201.94 15.462C201.084 14.7833 199.846 14.4432 198.23 14.4432C196.873 14.4432 195.563 14.7508 194.302 15.3647C193.04 15.9785 191.941 16.852 191.004 17.9837L185.718 14.1525C187.108 12.2447 189.016 10.7334 191.44 9.61862C193.864 8.50384 196.516 7.94504 199.393 7.94504C201.753 7.94504 203.831 8.33309 205.625 9.10921C207.42 9.88532 208.808 10.9691 209.796 12.359C210.782 13.7489 211.275 15.3167 211.275 17.0637C211.275 19.1662 210.693 21.1375 209.529 22.9805C208.365 24.8234 206.344 26.861 203.467 29.0906L194.446 36.0742H208.705Z" fill="#FFC300" />
    <path d="M256.627 8.52783L249.886 42.4764H242.514L246.491 22.3017L233.542 38.8879H230.05L223.066 22.1084L218.992 42.4778H211.621L218.411 8.52924H224.813L233.301 29.6748L249.887 8.52924H256.628L256.627 8.52783Z" fill="white" />
    <path d="M261.731 16.2833H269.393L264.225 42.4764H256.563L261.731 16.2833Z" fill="white" />
    <path d="M270.786 8.74937L269.852 14.2104H262.188L263.123 8.74937H270.786Z" fill="white" />
    <path d="M297.155 18.2998C298.868 19.8365 299.726 22.0265 299.726 24.8714C299.726 25.9382 299.612 26.9894 299.386 28.0238L296.476 42.4764H288.911L291.724 28.5092C291.852 27.9603 291.917 27.362 291.917 26.7143C291.917 25.3568 291.545 24.3224 290.802 23.6098C290.059 22.8986 288.943 22.543 287.457 22.543C285.516 22.543 283.908 23.1004 282.631 24.2166C281.354 25.3314 280.489 27.005 280.036 29.2359L277.417 42.4764H269.85L275.039 16.3849H282.217L281.636 19.1973C283.964 17.0637 286.824 15.9969 290.22 15.9969C293.129 15.9969 295.441 16.7659 297.155 18.2998Z" fill="white" />
    <path d="M329.412 31.4669H309.431C309.689 35.0243 311.935 36.8023 316.171 36.8023C317.496 36.8023 318.766 36.5921 319.979 36.1716C321.191 35.751 322.249 35.1527 323.155 34.3766L326.307 39.372C324.853 40.5361 323.187 41.4096 321.312 41.991C319.437 42.5724 317.464 42.8645 315.395 42.8645C312.711 42.8645 310.359 42.3875 308.338 41.4336C306.318 40.4797 304.765 39.1137 303.683 37.3357C302.599 35.5577 302.059 33.5045 302.059 31.1762C302.059 28.2989 302.714 25.7039 304.023 23.3925C305.333 21.0811 307.152 19.2706 309.478 17.9611C311.807 16.6516 314.457 15.9969 317.432 15.9969C319.953 15.9969 322.143 16.4653 324.003 17.4037C325.862 18.3421 327.301 19.6827 328.32 21.4296C329.338 23.1752 329.846 25.1973 329.846 27.4918C329.846 28.6884 329.701 30.0135 329.41 31.4683L329.412 31.4669ZM312.583 23.1004C311.354 24.0543 310.48 25.3723 309.964 27.0529H322.912C322.912 25.3723 322.386 24.0543 321.336 23.1004C320.285 22.1465 318.871 21.6695 317.093 21.6695C315.315 21.6695 313.811 22.1465 312.583 23.1004Z" fill="white" />
    <path d="M24.3417 24.5059C21.4927 24.5059 21.0383 30.5003 18.1836 30.5003H3.94406L3.17924 34.4966H15.9809C18.8355 34.4966 18.1836 30.5003 21.0383 30.5003H50.3387L51.5 24.5059H24.3417Z" fill="#FFC300" />
    <path d="M20.3102 32.5069C17.4611 32.5069 17.0067 38.5013 14.1521 38.5013H2.39184L1.60444 42.4976H11.9507C14.8054 42.4976 14.1535 38.5013 17.0082 38.5013H33.236L34.4735 32.5069H20.3102Z" fill="#FFC300" />
    <path d="M16.2786 40.5093C13.4296 40.5093 12.9752 46.5037 10.1205 46.5037H0.783168L0 50.5H7.91917C10.7739 50.5 10.1219 46.5037 12.9766 46.5037H31.7331L32.8987 40.5093H16.2786Z" fill="#FFC300" />
    <path d="M36.4364 0.5C33.5873 0.5 33.133 6.49441 30.2783 6.49441H8.70234L7.90647 10.4907H28.0755C30.9302 10.4907 30.2783 6.49441 33.133 6.49441H70.4978L71.741 0.5H36.4364Z" fill="white" />
    <path d="M32.4048 8.50243C29.5558 8.50243 29.1014 14.4968 26.2467 14.4968H7.17693L6.33026 18.4931H24.044C26.8987 18.4931 26.2467 14.4968 29.1014 14.4968H68.9216L70.1013 8.50243H32.4048Z" fill="white" />
    <path d="M28.3733 16.5034C25.5242 16.5034 25.0698 22.4979 22.2152 22.4979H5.50617L4.75545 26.4941H20.0138C22.8685 26.4941 22.2166 22.4979 25.0713 22.4979H51.8965L53.0762 16.5034H28.3733Z" fill="white" />
  </svg>
);


const PF2MTopBarMain = ({
  classes,
}) => (
  <div>
    <AppBar position="static" color="primary">
      <Toolbar variant="dense" className={classes.appBar}>
        <Fast2MineLogo className={classes.f2mLogo} />
        <Button
          exact="true"
          to="/"
          align="center"
        >
          <Typography
            className={classes.title}
          >
            MINING CONTROL
          </Typography>
        </Button>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: 10,
        }}
        >
          <UserMenu />
        </div>
      </Toolbar>
    </AppBar>
  </div>
);
PF2MTopBarMain.propTypes = {
  classes: PropTypes.object,
};

PF2MTopBarMain.defaultProps = {
  classes: {},
};

export default (withStyles(styles)(PF2MTopBarMain));
