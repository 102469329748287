import React from 'react';
import {
  AppBar, withStyles, Toolbar, Button,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Home from '@material-ui/icons/Home';
import Settings from '@material-ui/icons/Settings';
import styles from './styles';
import logo from '~/assets/logo_white.svg';
import PF2MExitButton from '../PF2MExitButton';
import { modules as modulesUrls } from '~/utils';

const PF2MTopBar = ({
  classes, modules, version, config,
}) => {
  const { t: translate } = useTranslation();

  const renderModules = () => {
    const enableOpenPit = config.find(c => c.key === 'enable_openpit' && c.value === '1');

    return (modules.map(m => ((modulesUrls[m.module_value] && modulesUrls[m.module_value].url) ? (
      <Button
        key={m.module_value}
        component={NavLink}
        to={m.module_value !== 'REPORTS' ? modulesUrls[m.module_value].url : { pathname: config.find(c => c.key === 'url_reports')?.value }}
        activeClassName={classes.active}
        className={classes.title}
        disabled={modulesUrls[m.module_value].disabled}
        target={m.module_value === 'REPORTS' ? '_blank' : undefined}
      >
        {translate(`common:${enableOpenPit && m.module_name === 'UndergroundModule' ? 'OpenPitModule' : m.module_name}`)}
      </Button>
    ) : null)));
  };

  return (
    <div>
      <AppBar position="static" color="primary">
        <Toolbar variant="dense" className={classes.appBar}>
          <div className={classes.grow}>
            {modules && renderModules()}
          </div>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: 10,
          }}
          >
            <span style={{
              fontFamily: 'Roboto',
              fontWeight: 300,
              fontSize: 14,
              color: '#CDCDCD',
              marginRight: 10,
            }}
            >
              {version}
            </span>
            <Button
              component={React.forwardRef((props, ref) => (<NavLink {...props} ref={ref} />))}
              exact
              to="/"
              activeClassName={classes.active}
              className={classes.title}
            >
              <Home />
            </Button>
            <Button
              component={React.forwardRef((props, ref) => (<NavLink {...props} ref={ref} />))}
              exact
              to="/configurations"
              activeClassName={classes.active}
              className={classes.title}
            >
              <Settings />
            </Button>
            <PF2MExitButton />
          </div>
          <img src={logo} alt="Fast2Mine Logo" />
        </Toolbar>
      </AppBar>
    </div>
  );
};
PF2MTopBar.propTypes = {
  classes: PropTypes.object,
  modules: PropTypes.array,
  version: PropTypes.string,
  config: PropTypes.array,
};

PF2MTopBar.defaultProps = {
  classes: {},
  version: '',
  modules: [],
  config: [],
};

const mapStateToProps = state => ({
  // eslint-disable-next-line no-underscore-dangle
  modules: state.auth.user._roles,
  version: state.auth.version,
  config: state.auth.user.config,
});

export default connect(mapStateToProps, {})(withStyles(styles)(PF2MTopBar));
