// Using this component to allow this component be changed by props
// The default separate the state from the props
// Case have an update in this third part maybe some functions doesn't work
import { MTableEditRow } from 'material-table';
import React from 'react';
import PropTypes from 'prop-types';

class CustomEditableRow extends MTableEditRow {
  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ data: { ...this.props.data } });
    }
  }
}

const EditableRow = (props, tableRef) => (
  <CustomEditableRow
    {...props}
    onEditingCanceled={(mode, rowData) => {
      const { state, dataManager } = tableRef;
      const rowList = state.renderData;
      const newRenderData = rowList.map((item) => {
        const tableData = { ...item.tableData, editing: null };
        if (item.tableData.id === rowData.tableData.id) {
          return item.tableData.original
            ? { ...item, ...item.tableData.original, tableData }
            : { ...item, tableData };
        }
        return item;
      });

      dataManager.setData(newRenderData);
      // eslint-disable-next-line react/destructuring-assignment
      tableRef.setState({
        ...dataManager.getRenderState(),
      });
    }}
  />
);

EditableRow.propTypes = {
  tableRef: PropTypes.object.isRequired,
};

export default EditableRow;
