import React from 'react';
import { useTranslation } from 'react-i18next';
import PF2MSearchSelect from '../../PF2MSearchSelect';

function lookup(func, item, rowData) {
  if (!func || !rowData) return true;
  return func(item, rowData);
}

const renderInactiveIcon = (text, value, active, translate) => (
  {
    label: text,
    inactiveCondition: active !== undefined && !active,
    tooltip: active !== undefined && !active ? translate('common:Inactive') : '',
    value,
  }
);

export default function FilterLookupCell(p) {
  const {
    rowData, columnDef, value, bulkEdit,
  } = p;

  const { t: translate } = useTranslation();

  return (
    <PF2MSearchSelect
      value={value}
      onChange={e => bulkEdit(e, p)}
    >
      {
        columnDef.lookupData
          .filter(item => lookup(columnDef.lookupFilter, item, rowData))
          .map(
            item => renderInactiveIcon(item.name,
              item[columnDef.lookupKey],
              item.active, translate),
          )
      }
    </PF2MSearchSelect>
  );
}
