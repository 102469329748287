import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import ExitToApp from '@material-ui/icons/ExitToApp';
import { withRouter } from 'react-router-dom';
import { logoutUser } from '~/store/auth/actions';
import { useTranslation } from 'react-i18next';
import PF2MAlertDialog from '../PF2MAlertDialog';

const PF2MExitButton = ({
  logout: logoutAction,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t: translate } = useTranslation();

  const handleLogout = async () => {
    setIsOpen(false);
    await logoutAction();
  };
  return (
    <div>
      <Button
        onClick={() => setIsOpen(true)}
      >
        <ExitToApp style={{ color: 'white' }} />
      </Button>
      <PF2MAlertDialog
        description={translate('validation:WouldYouLikeToExit')}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onConfirm={() => handleLogout()}
      />
    </div>
  );
};

PF2MExitButton.propTypes = {
  logout: PropTypes.func.isRequired,
};

const componentWithRouter = withRouter(PF2MExitButton);

export default connect(null, { logout: logoutUser })(componentWithRouter);
