import React, { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import NotInterested from '@material-ui/icons/NotInterestedOutlined';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Replay from '@material-ui/icons/Replay';
import CheckCircle from '@material-ui/icons/CheckCircle';
import GetApp from '@material-ui/icons/GetApp';
import Publish from '@material-ui/icons/Publish';
import AccessTime from '@material-ui/icons/AccessTime';
import { Badge } from '@material-ui/core';

const iconStyle = { color: 'rgb(242 170 0)', fontSize: '2rem' };

const icons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} style={iconStyle} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} style={iconStyle} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} style={iconStyle} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} style={iconStyle} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} style={iconStyle} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} style={iconStyle} />),
  FilterOff: forwardRef((props, ref) => <NotInterested {...props} ref={ref} style={iconStyle} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Reload: forwardRef((props, ref) => <Replay {...props} ref={ref} style={iconStyle} />),
  CheckCircle: forwardRef((props, ref) => <CheckCircle {...props} ref={ref} style={iconStyle} />),
  FileDownload: forwardRef((props, ref) => <GetApp {...props} ref={ref} style={iconStyle} />),
  FileUpload: forwardRef((props, ref) => <Publish {...props} ref={ref} style={iconStyle} />),
  AccessTime: forwardRef((props, ref) => <AccessTime {...props} ref={ref} style={iconStyle} />),
  AccessTimeDisabledDynamic: forwardRef((props, ref) => (
    <Badge badgeContent="!" color="error">
      <AccessTime {...props} ref={ref} style={iconStyle} />
    </Badge>
  )),
};

export default icons;
