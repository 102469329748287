import WebFont from 'webfontloader';
import bg4 from '~/assets/backgrounds/bg4.png';

WebFont.load({
  google: {
    families: ['Montserrat:700'],
  },
});

const styles = {
  badge: {
    '& .MuiBadge-badge': {
      width: 35,
      height: 35,
      padding: '5px 0px 0px 15px',
      top: 4,
      right: 4,
      borderRadius: 20,
      backgroundColor: '#FFC300',
      color: '#111110',
    },
  },
  badgeDisabled: {
    '& .MuiBadge-badge': {
      backgroundColor: '#B3B3B3',
      padding: '15px 0px 0px 20px',
      '& .MuiSvgIcon-root': {
        fontSize: 35,
      },
    },
  },
  container: {
    height: 'calc(100vh - 63px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: '#E6E7E8',
    backgroundImage: `url(${bg4})`,
  },
  cardsContainer: {
    padding: '0px 30px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gridColumnGap: '30px',
    gridRowGap: '30px',
    margin: '0px 200px',
  },
  cardContent: {
    width: 250,
    height: 150,
    border: '2px solid #B3B3B3',
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
  },
  cardLink: {
    '&:hover': {
      backgroundColor: '#FFC300',
      borderColor: '#FFC300',
    },
    '&:hover $cardIcon': {
      color: '#111110',
    },
  },
  cardDisabled: {
    cursor: 'default',
    backgroundColor: '#B3B3B3',
    borderColor: '#B3B3B3',
    '& $cardIcon': {
      color: '#808080',
    },
    '&:hover $cardIcon': {
      color: '#808080',
    },
    '&:hover': {
      backgroundColor: '#B3B3B3',
      borderColor: '#B3B3B3',
    },
  },
  cardIcon: {
    fontSize: '3rem',
    marginBottom: 10,
    color: '#FFC300',
  },
  cardTitle: {
    // fontFamily: 'Barlow Condensed',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 20,
    textTransform: 'uppercase',
    color: '#111110',
    padding: '0px 20px',
    wordBreak: 'break-word',
  },
  link: {
    color: 'transparent',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  linkDisabled: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  dynamicButton: {
    position: 'absolute',
    bottom: 25,
    right: 25,
    width: 250,
    height: 70,
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    color: '#111110',
    fontSize: 20,
    fontFamily: 'Montserrat',
    borderRadius: 20,
    border: 'none',
    cursor: 'pointer',
    '& svg': {
      fontSize: 30,
      marginTop: 5,
    },
  },
  modal: {
    width: '30vw',
    overflow: 'visible',
    display: 'flex',
    flexDirection: 'column',
  },
  knowMoreButton: {
    '& svg': {
      marginRight: 30,
    },
  },
  modalTitle: {
    fontSize: 26,
  },
  modalBody: {
    textAlign: 'center',
    padding: '0px 0px 0px 30px',
  },
  modalText: {
    fontSize: 20,
    padding: '0px 30px 30px 0px',
  },
};

export default styles;
